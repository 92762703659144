<template>
    <div
        ref="target"
        class="input-group-container airport flex-full min-w-[200px]"
        :class="{ 'has-value': inputData?.length }"
    >
        <label for="form-input-airport" class="input-group-title has-icon">{{ title }}</label>
        <div class="input-group" :class="{ error: validation ? validation.$error : false }">
            <div class="input-icon-prepend">
                <NuxtIcon name="ri:map-pin-2-fill" />
            </div>
            <input
                :id="`form-input-${name}`"
                v-model="inputData"
                type="text"
                :name="name"
                :placeholder="placeholder"
                class="tooltip-handle"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                @input="toggleDropdown(true)"
            />
            <div v-if="tooltip" class="tooltip">{{ tooltip }}</div>
            <div v-if="validation && validation.$error" class="error-tooltip">
                {{ validation.required.$message }}
            </div>
            <div class="input-icon-append" @click="clearInput()">
                <NuxtIcon name="ri:close-fill" />
            </div>
            <div
                v-show="
                    dropdown &&
                    ((algoliaResults && algoliaResults.hits.length) || (googleResults && googleResults.length))
                "
                class="dropdown-list"
            >
                <template v-if="algoliaResults && !google">
                    <div
                        v-for="option in algoliaResults.hits"
                        :key="option.id"
                        class="dropdown-list-item flex items-center alogiria"
                        @click="setOption(option)"
                    >
                        <NuxtIcon :name="`ri:${getIcon(option)}`" class="mr-3" :class="getIconClass(option)" />
                        <span
                            v-if="option.display && option.display.short"
                            v-html="formatHtmlResultItem(option.display.short)"
                        >
                        </span>
                    </div>
                </template>
                <template v-if="google && googleResults">
                    <template v-for="groupItems in transformList" :key="groupItems.type">
                        <div class="typeahead-list-category">{{ groupItems.type }}</div>
                        <div
                            v-for="option in groupItems.items"
                            :key="option.place_id"
                            class="dropdown-list-item flex items-center google"
                            @click="setOption(option)"
                        >
                            <NuxtIcon :name="`ri:${getIcon(option)}`" class="mr-3" :class="getIconClass(option)" />
                            <span v-html="formatHtmlResultItem(getNameForDropdown(option))"></span>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { Loader } from '@googlemaps/js-api-loader';
import { useSearchStore } from '../../../../../apps/_base/store/search';
import { fetchAlgolia, fetchGooglePredictions } from '~ui/composables/searchApi';
import type { ISearchDestination } from '~ui/types/components/Searchbox';
import { useGoogleMapApi } from '../../../../../apps/_base/store/googleMapApi';

const props: any = defineProps({
    name: { type: String, required: true, default: 'Airport' },
    title: { type: String, required: true, default: 'Title' },
    tooltip: { type: String, required: false },
    placeholder: { type: String, required: true, default: 'Placeholder' },
    value: { type: Object, required: false, default: () => ({ fullname: null }) },
    google: { type: Boolean, required: false, default: false },
    onlyAirports: { type: Boolean, required: false, default: true },
    validation: { type: Object, required: false, default: null },
});
// Init store
const searchStore = useSearchStore();
const config: any = useRuntimeConfig();
// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);
// Component Ref
const target = ref(null);
// Dropdown Status Ref
const dropdown: any = ref(false);
// Selected Object
const selected: any = ref(props.value);

const inputData = ref(selected.value?.fullname);
const algoliaResults = ref();
const googleResults = ref();
const apiService = ref();
const apiSessionToken = ref();
const apiGeocoder = ref();
const isGoogleApiLoaded = ref(false);
let googleInitLoad: Loader | null = null;

function initGoogleMaps() {
    if (import.meta.client && !isGoogleApiLoaded.value && props.google && !googleInitLoad) {
        const googleMapApiStore = useGoogleMapApi();
        googleInitLoad = googleMapApiStore.loader;
        googleInitLoad.load().then(async (google) => {
            googleInitLoad.importLibrary('places').then(async (places) => {
                apiService.value = new places.AutocompleteService();
                apiSessionToken.value = new places.AutocompleteSessionToken();
            });
            apiGeocoder.value = new google.maps.Geocoder();
            isGoogleApiLoaded.value = true;
        });
    }
}

initGoogleMaps();

// Setup Watch for Input
watch(inputData, async (val: any) => {
    if (val && val.length >= 3) {
        await fetchAllData(val);
    } else {
        algoliaResults.value = null;
        googleResults.value = null;
    }
});
// Outside click event
onClickOutside(target, () => (dropdown.value = false));

const getNameForDropdown = (item) => {
    if (item.provider === 'google') {
        return item.fullname;
    }
    return item.display.short;
};

const formatHtmlResultItem = (resultStr: string) => {
    const value = inputData.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    return resultStr.replace(new RegExp(`(${value})`, 'gi'), `<span class='matched-search'>$1</span>`);
};

const fetchAllData = async (val: string) => {
    algoliaResults.value = await fetchAlgolia(config.public, val, props.google, props.onlyAirports);
    if (props.google) {
        const results = await fetchGooglePredictions(apiService.value, apiSessionToken.value, val);
        const combinedArray = [...algoliaResults.value.hits, ...results];
        googleResults.value = excludeDuplicatesFromResults(combinedArray);
    }
};

const excludeDuplicatesFromResults = (items) => {
    return items.filter(
        (item, index, array) =>
            index === array.findIndex((findItem) => findItem.place_id === item.place_id) && item.type !== 'city'
    );
};

function getIcon(result: any): string[] {
    const icons: string[] = [];

    if (result.type === 'airport') {
        icons.push('plane-fill');
    }
    if (result.type === 'cruiseport') {
        icons.push('ship-fill');
    }
    if (result.type === 'city') {
        icons.push('building-fill');
    }
    if (result.type === 'landmark' || result.type === 'point of interest') {
        icons.push('bank-fill');
    }
    if (
        result.type === 'address' ||
        result.type === 'train_station' ||
        result.type === 'metro_station' ||
        result.type === 'district'
    ) {
        icons.push('map-pin-2-line');
    }

    return icons;
}
// Icons for Dropdown
function getIconClass(result: any) {
    return {
        'rotate-45': result.type === 'airport',
    };
}
function toggleDropdown(bool: boolean) {
    dropdown.value = bool;
}
function setOption(item: any) {
    selected.value = item;
    if (item.provider === 'google') {
        apiGeocoder.value?.geocode({ placeId: item.place_id }, function (results: any, status: any) {
            if (status === 'OK' && results[0]) {
                inputData.value = item.fullname;
                let itemParsed: ISearchDestination = {
                    fullname: item.fullname,
                    type: item.type,
                    country_code: results[0].address_components?.filter((x: any) => {
                        return x.types.includes('country') > 0;
                    })[0].short_name,
                    location: {
                        latitude: results[0].geometry?.location?.lat ?? null,
                        longitude: results[0].geometry?.location?.lng ?? null,
                    },
                };
                if (props.google) {
                    itemParsed = { ...itemParsed, place_id: item.place_id };
                }
                emit('update:value', itemParsed);
                dropdown.value = false;
            }
        });
    } else if (item.type.includes('airport') || item.type.includes('cruiseport')) {
        inputData.value = item.display.full;
        let itemParsed: ISearchDestination = {
            airport_code: item.hierarchy.airport_code,
            fullname: item.display.full,
            location: {
                latitude: item._geoloc.lat,
                longitude: item._geoloc.lng,
            },
            type: item.type,
        };
        if (props.google) {
            itemParsed = { ...itemParsed, place_id: item.place_id };
        }
        item.type.includes('cruiseport') ? searchStore.updateIsCruisePort(true) : searchStore.updateIsCruisePort(false);

        emit('update:value', itemParsed);
        dropdown.value = false;
    } else {
        inputData.value = item.fullname ? item.fullname : item.display.full;
        const itemParsed: ISearchDestination = {
            id: item.id,
            type: item.type,
            fullname: item.fullname ? item.fullname : item.display.full,
            country_code: item.hierarchy?.country?.code ?? null,
            location: {
                latitude: item._geoloc?.lat ?? null,
                longitude: item._geoloc?.lng ?? null,
            },
        };
        emit('update:value', itemParsed);
        dropdown.value = false;
    }
}

const transformList = computed(() => {
    const groups = {};

    if (googleResults.value) {
        googleResults.value.forEach((item, index) => {
            // Group items by the `type` property
            if (!Object.prototype.hasOwnProperty.call(groups, item.type)) {
                groups[item.type] = {
                    type: item.type,
                    items: [],
                };
            }
            // Add items to their respective group
            groups[item.type].items.push({
                ...item,
                index,
            });
        });
    }

    // Transform the groups object into an array
    return Object.values(groups);
});
function clearInput() {
    selected.value = null;
    inputData.value = null;
    emit('update:value', null);
    dropdown.value = false;
    props.validation.$reset();
}

// TODO: Need refactor it
// Update input if store value has changed
watch(
    () => searchStore.destination,
    (destination) => {
        if (!props.google) {
            inputData.value = destination.fullname;
            emit('update:value', destination);
            dropdown.value = false;
        }
    }
);
</script>

<style lang="postcss" scoped>
.input-group {
    .input-icon-append {
        @apply hidden z-[2] !cursor-pointer !pointer-events-auto;
    }

    &:hover {
        .input-icon-append {
            @apply block;
        }
    }
    .typeahead-list-category {
        @apply bg-[#1d3644] text-[0.8rem] capitalize text-[white] px-4 py-2;
    }

    .dropdown-list-item {
        .iconify {
            @apply !h-[1.125rem] !w-[1.125rem];
        }
    }
}
</style>
